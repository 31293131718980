import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SetPwService {
  options: {
    mode?: "SET" | "FORGOT";
    loginId?: string;
    mobile?: string;
  } = {};
  constructor() {}
}
