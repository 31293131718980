import { Injectable } from '@angular/core';
declare var navigator: any;
declare var window: any;

@Injectable()
export class ErrorService {

  public systemCodes: any = {};
  public serverCodes: any = {};

  constructor(
  ) {
    this.serverCodes = {
      0: "Something Went Wrong",
      100: "Continue",
      101: "Switching Protocols",
      200: "OK",
      201: "Created",
      202: "Accepted",
      203: " Non-Authoritative Information",
      204: "No Content",
      205: " Reset Content",
      301: " Moved Permanently",
      302: " Found",
      303: "See Other",
      305: " Use Proxy ",
      306: "(Unused)",
      400: " Bad Request",
      402: " Payment Required",
      403: "Forbidden",
      404: "Not Found",
      405: "Method Not Allowed",
      406: "Not Acceptable",
      408: "Request Timeout",
      409: "Conflict",
      410: "Gone",
      411: "Length Required",
      413: "Payload Too Large",
      414: "URI Too Long",
      415: "Unsupported Media Type",
      417: "Expectation Failed",
      426: "Upgrade Required",
      429: "Server is overloaded, try after some time",
      500: "Internal Server Error",
      501: "Not Implemented",
      502: "Bad Gateway",
      503: "Service Unavailable",
      504: "Gateway Timeout",
      505: "HTTP Version Not Supported",

    };
  }

  //lang implementation req
  parseError(errCode: number, errDesc: string, isSystem: boolean) {

    let _errDesc: string;
    if (errDesc != null && errDesc != '') {
      _errDesc = errDesc;

    } else {
      if (isSystem) {
        _errDesc = this.systemCodes[errCode];

      } else {
        _errDesc = this.serverCodes[errCode];
      }
    }

    return _errDesc;
  }

  // Code for discarding the error code

  parseMsg(msg: any=null, errCode?: number) {
    
    if(msg == null) return this.serverCodes[0];
    var idx = msg.indexOf(':');          
    return msg.slice(idx + 1).trim();
  }  
}