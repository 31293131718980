import { Injectable, WritableSignal, signal } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AlertDialogComponent } from "../dialogs/alert-dialog/alert-dialog.component";
import { DialogOptions } from "../dialogs/dialog-options";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  public loadingCount: WritableSignal<number> = signal(0);
  public loadingMessage: WritableSignal<string> = signal(
    "Please wait, loading..."
  );

  constructor(public _dialog: MatDialog) {}

  public showLoader(message: string = "Please wait, loading...") {
    this.loadingCount.update((value) => value + 1);
    this.loadingMessage.set(message);
  }

  public hideLoader() {
    this.loadingCount.update((value) => value - 1);
  }

  public openDialog(
    dialogOptions: DialogOptions
  ): MatDialogRef<AlertDialogComponent> {
    this._dialog.closeAll();
    return this._dialog.open(AlertDialogComponent, {
      data: dialogOptions,
      disableClose: dialogOptions.disableClose,
    });
  }
}
