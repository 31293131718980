import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogOptions } from "../dialog-options";
import { DialogType } from "../dialog-options";

@Component({
  selector: "app-alert-dialog",
  templateUrl: "./alert-dialog.component.html",
  styleUrls: ["./alert-dialog.component.scss"],
})
export class AlertDialogComponent implements OnInit {
  public readonly type: typeof DialogType = DialogType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogOptions,
    public dialogRef: MatDialogRef<AlertDialogComponent>
  ) {}

  ngOnInit(): void {}

  closeDialog(value: boolean): void {
    this.dialogRef.close(value);
  }
}
