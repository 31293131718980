import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, timeout } from "rxjs/operators";
import { throwError } from "rxjs";
import {
  environment,
  DEPLOYMENT_MODES,
  API_BASE_CONTEXT_ROOTS,
} from "../../../environments/environment";
import { CryptographyService } from "./cryptography.service";

export enum ServiceType {
  GENERATE_CAPTCHA,
  GENERATE_CAPTCHA_V2,
  VALIDATE_CAPTCHA,
  LOGIN,
  DOWNLOAD_FILE,
  CHECK_LOGIN_VALIDITY,
  FGP_GEN_OTP,
  CHECK_EXISTING_SESSION,
  LOGOUT_EXISTING_SESSION,
  GET_CONFIG,
  SEND_OTP_TO_LINKED_MOB,
  VALIDATE_OTP_AND_SET_PW,
}

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  private SERVER_URL: string = "";
  constructor(
    private http: HttpClient,
    private cryptographyService: CryptographyService
  ) {
    switch (
      environment.deployMode
      /* 
        any logic handling the deployment mode goes here.

        example from mobile app:
        the context root for production, training, and replica were different. 
        this was handled using the following cases:

        case DEPLOYMENT_MODES.SIT: this.SERVER_URL = `${environment.baseUrl}/mobile/sit`;
        break;

        case DEPLOYMENT_MODES.UAT: this.SERVER_URL = `${environment.baseUrl}/mobileapp`;
        break;

        case DEPLOYMENT_MODES.PROD: this.SERVER_URL = `${environment.baseUrl}/mobile`;
        break;
      */
    ) {
    }
    this.SERVER_URL = `${environment.baseUrl}`;
    console.log(this.SERVER_URL);
  }

  sendRequest(
    serviceType: ServiceType,
    payload: any,
    urlParams?: any,
    urlParams1?: any,
    customHeaders?: any[],
    isContentTypeExplicitlySet: boolean = true,
    customTimeoutInMs?: number
  ) {
    let contentType: string = "";
    if (isContentTypeExplicitlySet) {
      contentType = "application/json";
    }

    const { id, src, reqId, reqInitTime } = urlParams;
    const urlEndPart = `${src}/${reqId}/${reqInitTime}`;

    let URL: string = "";
    let requestType: string = "";

    let addtionalUrlParam: any;
    if (urlParams1) {
      let key = Object.keys(urlParams1)[0];
      addtionalUrlParam = `${key}=${urlParams1[key]}`;
    }
    let reqAuthHeader: boolean = false;
    let timeoutInMs: number = 60000;
    /* 
      if this is set dynamically in a configuration, can be loaded this way:
        let timeoutInMs: number = Config.getInstance().getReqTimeOut();
    */
    let responseType: any = null;
    switch (serviceType) {
      /*
        if a new service is handled, configure it below:

        case G2ServiceType.GET_CONFIGURATION:
                URL = `${this.SERVER_URL}/app/ws/lov/r/p/appSplash/${urlEndPart}`;
                requestType = "POST";
                reqAuthHeader = false;
                break;

        remember, it must be defined in the G2ServiceType enum. Define all other attributes relative to this.
      */
      case ServiceType.GENERATE_CAPTCHA:
        URL = `https://uat.wbifms.gov.in/gws/grips/api/captcha/gen/g/${urlEndPart}`;
        // URL = `http://172.17.2.53/gws/grips/api/captcha/gen/g/${urlEndPart}`;
        requestType = "GET";
        reqAuthHeader = false;
        break;

      // case ServiceType.GENERATE_CAPTCHA_V2:
      //   URL = `${API_BASE_CONTEXT_ROOTS.SECURITY}/v1.0`;
      //   requestType = "POST";
      //   reqAuthHeader = false;
      //   break;

      case ServiceType.VALIDATE_CAPTCHA:
        URL = `https://uat.wbifms.gov.in/gws/grips/api/json/generic.captcha.validate/${urlEndPart}`;
        // URL = `http://172.17.2.53/gws/grips/api/json/generic.captcha.validate/${urlEndPart}`;
        requestType = "POST";
        reqAuthHeader = false;
        break;

      case ServiceType.LOGIN:
        URL = `${API_BASE_CONTEXT_ROOTS.OAUTH2}/auth/token/fmw/${urlEndPart}`;
        requestType = "POST";
        reqAuthHeader = false;
        break;

      case ServiceType.CHECK_EXISTING_SESSION:
        URL = `${API_BASE_CONTEXT_ROOTS.OAUTH2}/auth/checkSession/${urlEndPart}`;
        requestType = "POST";
        reqAuthHeader = false;
        break;

      case ServiceType.LOGOUT_EXISTING_SESSION:
        URL = `${API_BASE_CONTEXT_ROOTS.OAUTH2}/auth/logout/${urlEndPart}`;
        requestType = "POST";
        reqAuthHeader = false;
        break;

      case ServiceType.CHECK_LOGIN_VALIDITY:
        URL = `${API_BASE_CONTEXT_ROOTS.USER_ADMIN}/v1.0/pwSetNeeded`;
        requestType = "POST";
        reqAuthHeader = false;
        break;

      case ServiceType.SEND_OTP_TO_LINKED_MOB:
        URL = `${API_BASE_CONTEXT_ROOTS.USER_ADMIN}/v1.0/sendOtpToLinkedMob`;
        requestType = "POST";
        reqAuthHeader = false;
        break;

      case ServiceType.FGP_GEN_OTP:
        URL = `${API_BASE_CONTEXT_ROOTS.USER_ADMIN}/v1.0/sendOtpToLinkedMob`;
        requestType = "POST";
        reqAuthHeader = false;
        break;

      case ServiceType.VALIDATE_OTP_AND_SET_PW:
        URL = `${API_BASE_CONTEXT_ROOTS.USER_ADMIN}/v1.0/validate-otp-set-pw`;
        requestType = "POST";
        reqAuthHeader = false;
        break;

      case ServiceType.GET_CONFIG:
        URL = `${API_BASE_CONTEXT_ROOTS.HOME}/v1.0/config?application=${environment.appName}&type=UI`;
        requestType = "GET";
        reqAuthHeader = false;
        break;
      //end
    }

    let httpHeaders: HttpHeaders = new HttpHeaders({});
    //the custom headers that will accompany each request.

    /**
     * Added for handling Generic request with Temporary Password
     */
    if (
      sessionStorage.getItem("o_data_1") != null &&
      sessionStorage.getItem("o_data_4") != null
    ) {
      reqAuthHeader = true;
    }
    if (sessionStorage.getItem("l_client_type") != null) {
      reqAuthHeader = true;
    }
    if (
      sessionStorage.getItem("c_data_1") != null &&
      sessionStorage.getItem("c_data_2") != null &&
      sessionStorage.getItem("c_data_3") != null
    ) {
      reqAuthHeader = true;
    }
    if (reqAuthHeader) {
      httpHeaders = new HttpHeaders({
        "Api-L2-Id":
          sessionStorage.getItem("c_data_1") == null
            ? ""
            : sessionStorage.getItem("c_data_1"),
        "Api-L2-Token":
          sessionStorage.getItem("c_data_3") == null
            ? ""
            : sessionStorage.getItem("c_data_3"),
        "Api-HS-Id":
          sessionStorage.getItem("o_data_1") == null
            ? ""
            : sessionStorage.getItem("o_data_1"),
        "Api-HS-Token":
          sessionStorage.getItem("o_data_4") == null
            ? ""
            : sessionStorage.getItem("o_data_4") +
              window.sessionStorage.getItem("o_data_5"),
        "Api-Client-Id":
          sessionStorage.getItem("o_data_6") == null
            ? ""
            : sessionStorage.getItem("o_data_6"),
        "Api-Access-Token":
          sessionStorage.getItem("o_data_7") == null
            ? ""
            : sessionStorage.getItem("o_data_7"),
        "Api-URole":
          sessionStorage.getItem("o_data_8") == null
            ? ""
            : sessionStorage.getItem("o_data_8"),
      });
    }

    //add GWS related headers for tamper proofing
    //Integrity token is checked server-side to ensure the request that client sent was not tampered with in transit
    //NR (non-repudiation) token is returned as-is by the server, and checked by when response is received. if packet switching has taken place, ie the NR token does not match, then error will be thrown.
    httpHeaders = httpHeaders.append(
      "Api-Integrity-Token",
      this.cryptographyService.convertObjectToSha512String(payload)
    );
    httpHeaders = httpHeaders.append(
      "Api-NR-Token",
      localStorage.getItem("unique-tab-id")
        ? this.cryptographyService.convertStringToSha512String(
            localStorage.getItem("unique-tab-id")
          )
        : ""
    );

    //add common headers
    httpHeaders = httpHeaders.append("Pragma", "no-cache");
    httpHeaders = httpHeaders.append("Expires", "0");
    httpHeaders = httpHeaders.append(
      "Cache-Control",
      "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
    );

    if (
      contentType &&
      isContentTypeExplicitlySet &&
      isContentTypeExplicitlySet == true &&
      contentType != ""
    ) {
      //if a content type in request needs to be specified, add the header
      httpHeaders = httpHeaders.append("Content-Type", contentType);
    }

    if (customHeaders) {
      for (let header of customHeaders) {
        console.log(header);
        httpHeaders = httpHeaders.append(header["key"], header["val"]);
      }
    }

    const lang = "en";
    /*
      when a config is being loaded, replace this statement with more appropriate logic like
      const lang = Config.getInstance().getLang() === 'ben' ? 'bn' : 'en';
    */
    httpHeaders = httpHeaders.append("Lang", lang);

    if (environment.isMobileApp === true) {
      httpHeaders = httpHeaders.append("Api-Ref-Id", "mgrps");
    } else {
      httpHeaders = httpHeaders.append("Api-Ref-Id", "grps");
    }

    if (serviceType === ServiceType.DOWNLOAD_FILE) {
      responseType = "blob";
    }

    let options = {
      headers: httpHeaders,
      responseType: responseType ? responseType : "",
      observe: responseType ? ("response" as "body") : ("body" as "body"),
    };

    if (customTimeoutInMs) {
      timeoutInMs = customTimeoutInMs;
    }

    if (requestType === "POST") {
      return this.http.post(URL, payload, options).pipe(
        timeout(timeoutInMs),
        catchError((error) => {
          return throwError(error);
        })
      );
    } else if (requestType === "GET") {
      //console.log("url----" + URL);
      return this.http.get(URL, options).pipe(
        timeout(timeoutInMs),
        catchError((error) => {
          return throwError(error);
        })
      );
    } else if (requestType === "PUT") {
      return this.http.put(URL, payload, options).pipe(
        timeout(timeoutInMs),
        catchError((error) => {
          return throwError(error);
        })
      );
    } else {
      // console.log("Nothing");
      return null;
    }
  }
}
