<div class="dialog-container">
  <section class="dialog-header flex justify-between">
    <div class="title-container flex gap-1">
      @if (data) {
        @if (data.type === type.SUCCESS) {
          <div class="success-icon-container flex-center">
            <mat-icon> check </mat-icon>
          </div>
        } @else if (data.type === type.ERROR) {
          <div class="failure-icon-container flex-center">
            <mat-icon> error </mat-icon>
          </div>
        } @else if (data.type === type.WARNING) {
          <div class="warning-icon-container flex-center">
            <mat-icon> warning </mat-icon>
          </div>
        } @else {
          <div class="info-icon-container flex-center">
            <mat-icon> info </mat-icon>
          </div>
        }
      } @else {
        <div class="warning-icon-container flex-center">
          <mat-icon> warning </mat-icon>
        </div>
      }
      <span class="title" [innerHTML]="data.title"></span>
    </div>

    @if (!data.disableClose) {
      <button class="close-btn plain-btn">
        <svg
          _ngcontent-ng-c1294587873=""
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            _ngcontent-ng-c1294587873=""
            d="M18.6532 13.3493L19.7732 12.2293M12.2265 19.7707L15.8932 16.104M19.7732 19.776L12.2265 12.2293M5.33317 8C3.6665 10.2267 2.6665 13 2.6665 16C2.6665 23.36 8.63984 29.3333 15.9998 29.3333C23.3598 29.3333 29.3332 23.36 29.3332 16C29.3332 8.64 23.3598 2.66667 15.9998 2.66667C14.0932 2.66667 12.2665 3.06667 10.6265 3.8"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </button>
    }
  </section>

  <section class="divider"></section>

  <section class="dialog-content flex-center" [innerHTML]="data.body"></section>

  @if (!data.disableClose) {
    <section
      class="dialog-actions flex gap-1 justify-end padding-1 mob-flex-column mob-justify-center mob-padding-bottom-3"
    >
      @if (!data.disableClose) {
        <button
          class="plain-btn primary-btn mat-elevation-z3"
          [innerHTML]="data.confirmLabel ? data.confirmLabel : 'Okay'"
          (click)="closeDialog(true)"
        ></button>
      }

      @if (data.showCancelButton) {
        <button
          class="plain-btn secondary-btn"
          [innerHTML]="data.cancelLabel ? data.cancelLabel : 'Cancel'"
          (click)="closeDialog(false)"
        ></button>
      }
    </section>
  }
</div>
