import { Injectable } from "@angular/core";
import { sha512 } from "js-sha512";
import { sha256 } from "js-sha256";
import { Md5 } from "ts-md5";
import * as Forge from "node-forge";
import { Buffer } from "buffer";

export enum HASH_FUNCTIONS {
  "MD5",
  "SHA256",
  "SHA512",
}

@Injectable({
  providedIn: "root",
})
export class CryptographyService {
  encrypt: any;
  decrypt: any;
  securityPubKey: string =
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgxkCoAqRIuwiT3V8oS5VpTCEiyvqw7EECH1AOM7YNpe0G5NxzX4xwLAWjv3dpX+tcvV4KozHh4dYu0DijWHOUdsgvEB7PShhRYSIButQv8t9SBTjwCECC5MN6WC/i8xYS368n6vJrQfOrcrPFc5VMHOX7dP6hHN+PkeDSoG1w2MOocyZz2sfOdGXUKTIsYhDhzzgCGZjb2PE/WEMJujwMkGP8EPazOm13e5EAoUhYzD81GdNPwSdVtFMpuyPmE5G5B8mvX7oFj3pcSgNw7RjQhAoR5ejK14JNxB7qb6jbwAYDtj5J0K8ZwgMf5L4ZrdCWtyg/8h32sTyeQlgn+kdZwIDAQAB";
  securityPvtKey: string =
    "MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCDGQKgCpEi7CJPdXyhLlWlMISLK+rDsQQIfUA4ztg2l7Qbk3HNfjHAsBaO/d2lf61y9XgqjMeHh1i7QOKNYc5R2yC8QHs9KGFFhIgG61C/y31IFOPAIQILkw3pYL+LzFhLfryfq8mtB86tys8VzlUwc5ft0/qEc34+R4NKgbXDYw6hzJnPax850ZdQpMixiEOHPOAIZmNvY8T9YQwm6PAyQY/wQ9rM6bXd7kQChSFjMPzUZ00/BJ1W0Uym7I+YTkbkHya9fugWPelxKA3DtGNCEChHl6MrXgk3EHupvqNvABgO2PknQrxnCAx/kvhmt0Ja3KD/yHfaxPJ5CWCf6R1nAgMBAAECggEAZI7kopo4Zc83Kjf2VzRRYZprsM0JEP5RF6nrfye6VXXcI2Lf4j4wL5tiUmCMiYv8IbgEaq79xgmdmFTW8pfZSJSK8ZuMbIbyxf440QXjNJsECCxt3kvzY6ly/FbH1pXyDEa0rM16rwzUDh8I5bMVSUsBeN4mxT5J0b/NB3OjVrEjVpMSO03qpncfeCvZbELGqT9rTmQwItTPilHVuk0gww1Hu+Y7hDNPp2K0eAU2ukVjPDQdCEA/sW28daUuLjbBAjvSjX6K28iBg8YX+ZnZrsMOV7s3ejztXwmDEIBQ8GteJtZmwFei4MIhSXqZo898qnbpWp/diO/C2M6m58W8cQKBgQDRUFjO3eICehkhhmpA+YjOJGibp1+YNuKkhkJ9SFAAXEtwTP8mVviD/9VztqImZa0nyTmG7XehXw/Rh5aSKFyzvbRAJVHMBgE0Hn4S1KeG4O3tIkhS+PjCSr5HSMk06rVa66gbcQPWYOMbRePhCPmLdmXPPNu+POVTPOOe0Qsl1QKBgQCgVpVeW/pw59cl2fpls0ViUdHe7Vupqofl4BwKyiwQYD4y5tsw+FtdLkqzVjdB69fpckEcFagJMIemrTb83B0hPGm3HY2r/GudIdGXSQkdzgSOdxj9kZe+5xJDPRSCTW8Yq0UV26c6O0jVgPWLoDkX1WHeqHKbKNIl+shfS+zoSwKBgB9z62RqgyPMJSqcgCrzVFNltTJunDbLSJNgeW1KCHuFYvClIVIpj36GWxTgRM1wSezImBRmaqudFUtvDHk6B3Qa9ZMOCJSX8mZajqdVvTbPZfFUTXnqX7cSdDVSAqoo+seG0eQNv33fYUEf53ZRWu5SBEp34q1biTpQ1ANxdjNhAoGBAIzix6j8BRmEfeYCnSUT2xnDbzgFNI/nCjaRL9LDRui0yLKNa3iiIhXEgVsHnNJN25CDFncJCHfarrdkaHbTKr1ziiiezUecKysM9k+Km3oW9qYAPHNCpSXXgJKYbxb313DDvZaxRUXDpgIEqL2hcclhc/fj56AyxEUfEUW7P1X1AoGAPJuASg/W2LLuwJly65lsAKDfOmw+6bjEyrya2LT51RtWQTuH/TIh2LSpfBWOggOjIquwJiWE9unikJ1fPPt2gL6p6URmi11yu+Qjj/1484/aOQN+tTUEKousTXEllod/eQ/PFgrThLGnA/mkXJecq8LrZZrVQVEUK6fymhT1y5k=";

  constructor() {}
  ngOnInit(): void {}
  convertStringToSha512String(inputString: string): string {
    return sha512(inputString);
  }

  convertObjectToSha512String(inputObject: any): string {
    return sha512(JSON.stringify(inputObject));
  }

  convertStringToSha256String(inputString: string): string {
    return sha256(inputString);
  }

  convertObjectToSha256String(inputObject: any): string {
    return sha256(JSON.stringify(inputObject));
  }

  convertStringToBase64String(inputString: string): string {
    return btoa(inputString);
    // return Buffer.from(inputString).toString("base64");
  }

  convertBase64StringToPlaintext(inputBase64String: string): any {
    // return atob(inputBase64String);
    return Buffer.from(inputBase64String, "base64").toString("utf-8");
  }

  sanitizeAllJsonFieldsToBase64(inputJson: any): any {
    let outputJson = { ...inputJson };

    for (let key of Object.keys(inputJson)) {
      console.log(
        key +
          " -> " +
          inputJson[key] +
          " : " +
          typeof inputJson[key] +
          "length: " +
          inputJson[key].length
      );
      outputJson[key] = "base64:" + btoa(inputJson[key]);
    }

    console.log("Sanitized JSON: " + JSON.stringify(outputJson));
    return outputJson;
  }

  sanitizeSpecificJsonFieldsToBase64(
    inputJson: any,
    fieldsToSanitize: string[]
  ): any {
    let outputJson = { ...inputJson };

    for (let key of Object.keys(inputJson)) {
      if (fieldsToSanitize.indexOf(key) != -1) {
        outputJson[key] = "base64:" + btoa(inputJson[key]);
      }
    }

    console.log("Sanitized JSON: " + JSON.stringify(outputJson));
    return outputJson;
  }

  convertStringToMD5String(inputString: string): string {
    return Md5.hashStr(inputString);
  }

  encryptWithRSAPublicKey(inputString: string): string {
    this.encrypt =
      "`-----BEGIN PUBLIC KEY-----" +
      this.securityPubKey +
      "-----END PUBLIC KEY-----`";
    const rsa = Forge.pki.publicKeyFromPem(this.encrypt);
    return this.convertStringToBase64String(
      rsa.encrypt(inputString.toString())
    );
  }

  decryptWithRSAPrivateKey(inputString: string): string {
    this.decrypt =
      "`-----BEGIN PRIVATE KEY-----" +
      this.securityPvtKey +
      "-----END PRIVATE KEY-----`";
    const rsa = Forge.pki.privateKeyFromPem(this.decrypt);
    return this.convertStringToBase64String(
      rsa.decrypt(inputString.toString())
    );
  }

  verifyIntegrity(
    data: string,
    providedHash: string,
    hashFunction: HASH_FUNCTIONS
  ): boolean {
    let computedHash: string;

    if (hashFunction === HASH_FUNCTIONS.MD5) {
      computedHash = this.convertStringToMD5String(data);
    } else if (hashFunction === HASH_FUNCTIONS.SHA256) {
      computedHash = this.convertStringToSha256String(data);
    } else if (hashFunction === HASH_FUNCTIONS.SHA512) {
      computedHash = this.convertStringToSha512String(data);
    }

    return computedHash === providedHash;
  }
}
