export enum DialogType {
  INFO,
  SUCCESS,
  ERROR,
  WARNING,
}

export interface DialogOptions {
  type?: DialogType;
  title: string;
  body: string;
  confirmLabel?: string;
  showCancelButton?: boolean;
  cancelLabel?: string;
  disableClose?: boolean;
}
