import { Injectable } from "@angular/core";
import { Observable, Subject, TimeoutError, retry, takeUntil } from "rxjs";
import { NetworkService, ServiceType } from "./network.service";
import { ErrorService } from "./error.service";
import { CryptographyService } from "./cryptography.service";
import { Router } from "@angular/router";
import {
  API_BASE_CONTEXT_ROOTS,
  environment,
} from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ReqrescontrollerService {
  private timeoutMsg: string;
  public _demoReqCall = new Subject<boolean>();

  constructor(
    private _networkService: NetworkService,
    private _errorService: ErrorService,
    private _cryptoService: CryptographyService,
    private router: Router,
    private _http: HttpClient
  ) {
    this.timeoutMsg = "Request timed out.";
  }

  urlParamFormation() {
    return {
      id: "10",
      src: environment.isMobileApp ? "fmw-login" : "fmw-login-mob",
      reqId:
        window.localStorage.getItem("g2-engine-id") === undefined
          ? "fmw-login"
          : window.localStorage.getItem("g2-engine-id") +
            "_" +
            `${"" + Math.floor(Math.random() * Math.pow(10, 10))}`,
      reqInitTime: `${Date.now()}`,
    };
  }

  generateReqJson(reqType: string, reqData: any = null): any {
    return {
      reqId: uuidv4(),
      reqType: reqType,
      reqData: reqData,
    };
  }

  generateCaptcha(): Observable<any> {
    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();
      this._networkService
        .sendRequest(ServiceType.GENERATE_CAPTCHA, null, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe(
          (data: any) => {
            if (data.resp != null || data.resp != undefined) {
              observer.next(data.resp);
            } else {
              observer.error(
                // this._errorService.parseMsg("Something Went Wrong", 0)
                data.resp.errDesc ? data.resp.errDesc : "Something went wrong."
              );
            }
            observer.complete();
          },
          (err) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
          () => {}
        );
    });
  }

  generateCaptchaV2(): Observable<any> {
    let reqJson = this.generateReqJson("CAPTCHA_GEN");
    let url = API_BASE_CONTEXT_ROOTS.SECURITY + "/v1.0";

    return this._http.post(url, reqJson);
  }

  validateCaptcha(): Observable<any> {
    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();
      this._networkService
        .sendRequest(ServiceType.VALIDATE_CAPTCHA, null, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe(
          (data: any) => {
            if (
              data.resp &&
              data.resp.data &&
              Array.isArray(data.resp.data) &&
              data.resp.data[0] &&
              data.resp.data[0].status &&
              data.resp.data[0].status == "S"
            ) {
              observer.next(true);
            } else {
              observer.error(
                // this._errorService.parseMsg("Something Went Wrong", 0)
                data.resp.errDesc ? data.resp.errDesc : "Something went wrong."
              );
            }
            observer.complete();
          },
          (err) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
          () => {}
        );
    });
  }

  validateCaptchaV2(): Observable<any> {
    let reqJson = this.generateReqJson("CAPTCHA_VERIFY", {
      captchaId: sessionStorage.getItem("c_data_1"),
      captchaValue: sessionStorage.getItem("c_data_3"),
    });

    let url = API_BASE_CONTEXT_ROOTS.SECURITY + "/v1.0";

    return this._http.post(url, reqJson);
  }

  login(req: any): Observable<any> {
    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();

      this._networkService
        .sendRequest(ServiceType.LOGIN, req, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe(
          (data: any) => {
            if (
              data.resp &&
              data.resp.data &&
              data.resp.status &&
              data.resp.status == "S"
            ) {
              observer.next(data.resp.data);
            } else {
              observer.error(
                // this._errorService.parseMsg("Something Went Wrong", 0)
                data.resp.errDesc ? data.resp.errDesc : "Something went wrong."
              );
            }
            observer.complete();
          },
          (err) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
          () => {}
        );
    });
  }

  checkLoginValidity(): Observable<any> {
    return new Observable();
  }

  checkSession(req: any): Observable<any> {
    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();

      this._networkService
        .sendRequest(ServiceType.CHECK_EXISTING_SESSION, req, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe(
          (data: any) => {
            if (data?.resp?.status == "S") {
              observer.next(data?.resp?.data);
            } else {
              observer.error(
                // this._errorService.parseMsg("Something Went Wrong", 0)
                data.resp.errDesc ? data.resp.errDesc : "Something went wrong."
              );
            }
            observer.complete();
          },
          (err) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
          () => {}
        );
    });
  }

  logoutExistingSession(req: any): Observable<any> {
    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();

      this._networkService
        .sendRequest(ServiceType.LOGOUT_EXISTING_SESSION, req, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe(
          (data: any) => {
            if (data?.resp?.status == "S") {
              observer.next(data?.resp?.data);
            } else {
              observer.error(
                // this._errorService.parseMsg("Something Went Wrong", 0)
                data.resp.errDesc ? data.resp.errDesc : "Something went wrong."
              );
            }
            observer.complete();
          },
          (err) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
          () => {}
        );
    });
  }

  getConfig(): Observable<any> {
    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();

      this._networkService
        .sendRequest(ServiceType.GET_CONFIG, null, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe({
          next: (response: any) => {
            if (response?.sts?.status == "S") observer.next(response?.respData);
            else observer.error("Something went wrong.");

            observer.complete();
          },
          error: (err: any) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
        });
    });
  }

  checkPwSetNeeded(req: any): Observable<any> {
    let reqJson = this.generateReqJson("doesUserNeedToSetPassword", req);

    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();

      this._networkService
        .sendRequest(ServiceType.CHECK_LOGIN_VALIDITY, reqJson, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe({
          next: (response: any) => {
            if (response?.sts?.status == "S") observer.next(response?.respData);
            else observer.error("Something went wrong.");

            observer.complete();
          },
          error: (err: any) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
        });
    });
  }

  sendOtpToLinkedMob(req: any): Observable<any> {
    let reqJson = this.generateReqJson("sendOtpToLinkedMob", req);

    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();

      this._networkService
        .sendRequest(ServiceType.SEND_OTP_TO_LINKED_MOB, reqJson, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe({
          next: (response: any) => {
            if (response?.sts?.status == "S") observer.next(response);
            else observer.error("Something went wrong.");

            observer.complete();
          },
          error: (err: any) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
        });
    });
  }

  validateOtpAndSetPw(req: any): Observable<any> {
    let reqJson = this.generateReqJson("validateOtpAndSetPw", req);

    return new Observable((observer) => {
      const urlParams = this.urlParamFormation();

      this._networkService
        .sendRequest(ServiceType.VALIDATE_OTP_AND_SET_PW, reqJson, urlParams)
        .pipe(retry(0), takeUntil(this._demoReqCall))
        .subscribe({
          next: (response: any) => {
            if (response?.sts?.status == "S") observer.next(response);
            else observer.error("Something went wrong.");

            observer.complete();
          },
          error: (err: any) => {
            console.log("timeoutMsg------" + err);
            const errMsg =
              err instanceof TimeoutError
                ? err["message"]
                : this._errorService.parseError(err.status, null, false);
            console.log(errMsg);
            observer.error(errMsg);
            observer.complete();
          },
        });
    });
  }
}
